import { useLocation, useNavigate } from "react-router-dom";

import "./style.css";
import { useIntl } from "react-intl";

export const Menu = () => {
  const intl = useIntl();

  const location = useLocation();

  const navigate = useNavigate();

  const handlerClickWallet = () => {
    navigate("/wallet");
  };

  const handlerClickDeal = () => {
    navigate("/deals/create");
  };

  const handlerClickAds = () => {
    navigate("/chat");
  };

  const handlerClickHelp = () => {
    navigate("/faq");
  };

  const handlerClickProfile = () => {
    navigate("/account/settings");
  };

  return (
    <>
      <div className="text-gray-700 p-2 pt-3 d-flex align-items-center justify-content-around menu-wrapper-custom rounded bg-white border-top-gray-90 pb-6 pb-lg-1 p-lg-1">
        <button
          className={
            `w-100px w-lg-50px  btn fs-6 p-0 me-lg-13 text-hover-primary ` +
            (location.pathname.startsWith("/wallet") && " text-primary")
          }
          onClick={handlerClickWallet}
        >
          <i
            className={
              `ki-duotone ki-wallet fs-2 ` +
              (location.pathname.startsWith("/wallet") && " text-primary")
            }
          >
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
          </i>
          <div className="fs-7">
            {intl.formatMessage({ id: "MENU.PROFILE_WITHDRAW" })}
          </div>
        </button>

        <button
          className={
            `w-100px w-lg-50px btn fs-6 p-0 p-lg-1 me-lg-12 text-hover-primary  ` +
            (location.pathname.startsWith("/chat") && " text-primary")
          }
          onClick={handlerClickAds}
        >
          <i
            className={
              `ki-duotone ki-message-text  fs-2 pe-0 ` +
              (location.pathname.startsWith("/chat") && " text-primary")
            }
          >
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>

          <div className="fs-7">{intl.formatMessage({ id: "CHAT" })}</div>
        </button>

        <button
          className={
            `w-100px w-lg-50px btn fs-6 p-0 p-lg-1  me-lg-12 text-hover-primary ` +
            (location.pathname.startsWith("/deals/") && " text-primary")
          }
          onClick={handlerClickDeal}
        >
          <i
            className={
              `ki-duotone ki-add-files fs-2 ` +
              (location.pathname.startsWith("/deals/") && " text-primary")
            }
          >
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>
          <div className="fs-7">
            {intl.formatMessage({ id: "MENU.DEAL_CARD_ACCOUNT" })}
          </div>
        </button>

        <button
          className={
            `w-100px w-lg-50px btn fs-6 me-lg-12 p-0 p-lg-1 text-hover-primary ` +
            (location.pathname.startsWith("/faq") && " text-primary")
          }
          onClick={handlerClickHelp}
        >
          <i
            className={
              `ki-duotone ki-message-question fs-2 ` +
              (location.pathname.startsWith("***") && " text-primary")
            }
          >
            <span className="path1 text-gray-700"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>
          <div className="fs-7">
            {intl.formatMessage({ id: "MENU.GLOBAL_HELP" })}
          </div>
        </button>

        <button
          className={
            `w-100px w-lg-50px btn fs-6 p-0 p-lg-1 me-lg-12 text-hover-primary  ` +
            (location.pathname.startsWith("/account/overview") &&
              " text-primary")
          }
          onClick={handlerClickProfile}
        >
          <i
            className={
              `ki-duotone ki-profile-circle  fs-2 ` +
              (location.pathname.startsWith("/account/overview") &&
                " text-primary")
            }
          >
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>
          <div className="fs-7">
            {intl.formatMessage({ id: "MENU.PROFILE_TITLE" })}
          </div>
        </button>
      </div>
    </>
  );
};
